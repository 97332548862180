<template>
  <FileUpload
    :name="name"
    :multiple="multiple"
    chooseLabel="Agregar"
    uploadLabel="Subir"
    cancelLabel="Eliminar"
    :showUploadButton="upload"
    :disabled="disabled"
    class="file"
  >
    <template #empty>
      <p class="empty">{{ placeholder }}</p>
    </template>
  </FileUpload>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "upload",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: Boolean,
      default: true,
    },
    upload: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Arrastre los archivos que desea subir",
    },
  },
};
</script>

<style scoped>
.file {
  width: 350px;
  border-radius: 10px !important;
  border: 2px solid var(--primary) !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  box-shadow: 0 10px 50px rgba(35, 141, 130, 0.15) !important;
  margin: 1rem 0 0.5rem 0;
}
.empty {
  margin: 1rem 0 !important;
  text-align: center;
  color: var(--primary-dark) !important;
}
</style>
